*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}
.title{
    position: absolute;
    top: calc(50% - 5vh);
    left: 0;
    width: 100%;
    color: #fff;
    font-size: 10vh;
    text-align: center;
}

.guify-container_be6yU{
    display: none;
}
